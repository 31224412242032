import * as React from "react"
import * as styles from "../styles/home.module.scss"
import Template from "../components/ui/Template"
import { Button } from "@material-ui/core"
import { Helmet } from "react-helmet"

class IndexPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    return <Template currentPage="what-we-do" lightnessTheme="light">
      <Helmet>
        <title>Daya Selaras Group - What We Do</title>
      </Helmet>
      <div className={styles.halfPage} style={{backgroundColor: "#ffffff", paddingTop: "128px", paddingBottom: "32px"}}>
        <div className={styles.fence}>
          <div className={`${styles.mission} ${styles.missionWithResponsiveImage}`}>
            <div className={`${styles.responsiveImage} ${styles.responsiveImageLongText}`} style={{ alignItems: "center" }}>
              <video autoplay="true" loop controls style={{ width: "100%", aspectRatio: "16/9" }}>
                <source src="/static/videos/company-profile.mp4" />
              </video>
            </div>
            <div className={styles.missionItemLongText}>
              <p>We are a <span className={styles.overline}>SUSTAINABLE INDUSTRIAL HUB</span> linking the industrial loop from waste paper to corrugated carton packaging.</p>

              <p>We have a network of partners and/or affiliates in the waste paper, industrial paper, corrugated carton box and logistics sectors, therefore forming a circular value chain.</p>

              <p>Industrial paper is made up of 100% recyclable waste paper, which is then used to produce corrugated carton packaging. The waste paper is then collected back into the loop.</p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.halfPage} style={{backgroundColor: "#c7d6cd", paddingTop: "32px", paddingBottom: "32px"}}>
          <div className={styles.fence}>
              <div className={`${styles.mission} ${styles.missionWithResponsiveImage}`}>
                  <div className={styles.titleWithDescription} style={{maxWidth: "300px"}}>
                    <div className={styles.titleWithDescription__title} style={{display: "flex", flexDirection: "column"}}><span>CIRCULAR</span> <span>VALUE CHAIN</span></div>
                  </div>
                  <div className={styles.responsiveImage} style={{backgroundImage: "url('/static/images/circular-value-chain.png')"}}></div>
              </div>
          </div>
      </div>
      <div className={styles.halfPage} style={{backgroundColor: "#d9d9d9", paddingTop: "32px", paddingBottom: "32px"}}>
          <div className={styles.fence}>
              <div className={`${styles.mission} ${styles.missionWithResponsiveImage}`}>
                  <div className={styles.titleWithDescription}>
                    <div className={styles.titleWithDescription__title}><span>OUR</span> <span>CIRCULAR</span> <span>BUSINESS</span> <span>MODEL</span></div>
                  </div>
                  <div className={styles.responsiveImage} style={{backgroundImage: "url('/static/images/business-model.png')"}}></div>
              </div>
          </div>
      </div>
    </Template>
  }
}

export default IndexPage
